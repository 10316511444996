import React, { Component } from 'react';
import './Review.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReviewContent from "./ReviewContent"

const settings = {
    arrows: true,
    initialSlide: 0,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
};
export default props => (
    <div className="reviewContainer">
        <Slider {...settings} initialSlide={props.initialSlide}>
            {ReviewContent[props.slideReviewID].map((slide, i) => {
                var stylereview = slide.subheader.length>=120?"bigReview":"smallReview"
                return (
                    <div key={i}>
                        <div className="reviewStyle">   
                            <div className="separator">★★★★★</div>
                            <div className={stylereview}>
                                <h4><span >{slide.header}</span></h4>
                                <p className="vertical-centered">{slide.subheader}</p>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Slider>
    </div>
)