import React from "react"

export default props => (
  <h1
    className={`${props.className}`}
    style={{ color: props.headingTitleColor1 }}
    itemProp="name"
  >
    {props.headingTitleText1}{" "}
    <span style={{ color: props.headingColor1 }}>{props.headingText1}</span>
  </h1>
)
